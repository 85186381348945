$button-width: 150px;
$spinner-width: $button-width/6;
$blue: #0076d3;
$btn-bg: #8BC34A;
$text-light: #fefefe;

*,
*::before,
*::after {
  box-sizing: border-box;
}


:root {
  --select-border: #777;
  --select-focus: blue;
  --select-arrow: var(--select-border);
}

select {

  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;


  z-index: 1;


  &::-ms-expand {
    display: none;
  }

 
  outline: none;
}

.select {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;
  margin:auto;

  select,
  &::after {
    grid-area: select;
  }

  min-width: 15ch;
  max-width: 20ch;

  border: 1px solid var(--select-border);
  border-radius: 0.25em;
  padding: 0.25em 0.5em;

  font-size: 1rem;
  cursor: pointer;
  line-height: 1.1;

  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);


  &:not(.select--multiple)::after {
    content: "";
    justify-self: end;
    width: 0.8em;
    height: 0.5em;
    background-color: var(--select-arrow);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  }
}



select:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid var(--select-focus);
  border-radius: inherit;
}

select[multiple] {
  padding-right: 0;

  /*
   * Safari will not reveal an option
   * unless the select height has room to 
   * show all of it
   * Firefox and Chrome allow showing 
   * a partial option
   */
  height: 6rem;

  option {
    white-space: normal;


    outline-color: var(--select-focus);
  }

}

.select--disabled {
  cursor: not-allowed;
  background-color: #eee;
  background-image: linear-gradient(to top, #ddd, #eee 33%);
}

label {
  display:inline-block;
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom:5px;
  
}

.select + label {
  margin-top: 2rem;
}

body {
    min-height: 100vh;
    display: grid;
  
    grid-gap: 0.5rem;
    font-family: "Baloo 2", sans-serif;
    background-color: #e9f2fd;
    padding: 1rem;
  }

.screen-box {
  position: block;
  width: 100vw;
}


.input-select {

    margin-bottom: 2ch;
}

.inputs {
    display:inline-block;
    margin-right: 2ch;
}

input {
    border: 1px solid var(--select-border);
    border-radius: 0.25em;
    padding: 0.25em 0.5em;
  
    font-size: 1rem;
    line-height: 1.1;
}
  
.button{
    display: inline-block;
    min-width: $button-width;
    margin: auto;
    margin-bottom: 2rem;

    background: $btn-bg;
    color: $text-light;
    font-size: 1.2em;
    padding: 1em;
    border-radius: 4px;
    text-align: center;
    position: relative;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    border: 0;
    transition: border-radius linear 0.05s, width linear 0.05s;
  
    
    &:focus{
      outline: 0;
    }
    
    &.animate{
      width: $button-width/2.2;
      height: $button-width/2.2;
      min-width: 0;
      border-radius: 50%;
      color: transparent;
      
      &:after{
        position: absolute;
        content: '';
        width: $spinner-width;
        height: $spinner-width;
        border: 4px solid $text-light;
        border-radius: 50%;
        border-left-color: transparent;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        animation: spin ease-in 2.5s forwards;
        animation-name: spin;
        -webkit-animation-name: spin; 
        transition-timing-function:ease-in-out;
        -webkit-transition-timing-function: ease-in-out;
        animation-duration: 2.5s;
        -webkit-animation-duration: 2.5s;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
      }
  
      &.success:before{
        position: absolute;
        content: '';
        width: $spinner-width;
        height: $spinner-width/2;
        border: 4px solid $text-light;
        border-right: 0;
        border-top: 0;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(0deg) scale(0);
        transform: translate(-50%, -50%) rotate(0deg) scale(0);
        -webkit-animation: success ease-in 0.15s forwards;
        animation: success ease-in 0.15s forwards;
        animation-delay: 2.5s;
      }
      
      &.error{
        position: relative;
        -webkit-animation: vibrate ease-in 0.5s forwards;
        animation: vibrate ease-in 0.5s forwards;
        -webkit-animation-delay: 2.5s;
        animation-delay: 2.5s;
       
        &:before{
          color: #fff;
          position: absolute;
          content: '!';
          font-size: 1.8rem;
          font-weight: bold;
          text-align: center;
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%) scale(0);
          transform: translate(-50%, -50%) scale(0);
          -webkit-animation: error ease-in 0.5s forwards;
          animation: error ease-in 0.5s forwards;
          animation-delay: 2.5s;
        }
      }
    }
  }
  
      
  @keyframes spin {
      0% { transform: translate(-50%, -50%) rotate(0deg) scale(1);}
      90% { transform: translate(-50%, -50%) rotate(1080deg) scale(1); }
      100% { transform: scale(0); }
      }
  
  @-webkit-keyframes spin {
    0% {-webkit-transform: translate(-50%,-50%) rotate(0deg) scale(1);}
  
    98% {-webkit-transform: translate(-50%,-50%) rotate(1080deg) scale(1);}
  
    100% {-webkit-transform: translate(-50%,-50%) rotate(1080deg) scale(0);}
  }
      
  @keyframes success{
      from {
         transform: translate(-50%, -50%) rotate(0) scale(0);
         }
      to {
        transform: translate(-50%, -50%) rotate(-45deg) scale(1);
      }
  }
  
  @-webkit-keyframes success{
      from {
         -webkit-transform: translate(-50%, -50%) rotate(0) scale(0);
         }
      to {
        -webkit-transform: translate(-50%, -50%) rotate(-45deg) scale(1);
      }
  }
  
  @keyframes error {
      from { 
      transform: translate(-50%, -50%) scale(0);
      }
      to { 
      transform: translate(-50%, -50%) scale(1);
      background-color: #f44336;
      }
  }
  
  @-webkit-keyframes error {
      from { 
      -webkit-transform: translate(-50%, -50%) scale(0);
      }
      to { 
      -webkit-transform: translate(-50%, -50%) scale(1);
      background-color: #f44336;
      }
  }
  
  
  @keyframes vibrate {
      0%, 30%, 60%, 85%, 100% { 
        left: 0;
        background-color: #f44336;
      }
      10%, 40%, 90%, 70% { 
        left: -2px;
        background-color: #f44336;
      }
      20%, 50%, 80%, 95% { 
        left: 2px;
        background-color: #f44336;
      }
  }


  .more-info {
    margin-left: 2rem;
  }

  .export-button {
    background-color: DodgerBlue;
    font-size: .8em;
    padding: 1em;
    border-radius: 4px;
    border: none;
    color: white;
    margin-bottom: 2rem;
  }